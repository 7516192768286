import React from "react";
import { GoogleMap, Marker, withScriptjs, withGoogleMap } from "react-google-maps"
import { Card, CardContent, CardMedia, Typography } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    card: {
        maxWidth: 450,
        minWidth: 300,
        width: '100%',
        fontFamily: "Lato",
        textAlign:"left",
        backgroundColor: "rgba(255,255,255,0.1)",
    },
    title: {
      fontSize: 18,
      fontFamily: "Lato",
      textAlign:"left",
      backgroundColor: "rgba(255,255,255,0.1)",
    },
  });
  
  

const FuneralHome = () => {
    const classes = useStyles();
    return (
    <Card className={classes.card}>
        <CardContent className={classes.card}>
            <Typography className={classes.title}>
                Sullivan Funeral Home
            </Typography>
            <Typography className={classes.title}>
                2 Maquan St, <br/> 
                Hanson, MA 02341 <br/>
            </Typography>
        </CardContent>
        <CardMedia className={classes.Card}>
            <div className="FuneralHome">
                <GoogleMap
                    width={275}
                    defaultZoom={16}
                    defaultCenter={{ lat: 42.0635381, lng: -70.8603076 }}
                >
                    <Marker
                        position={{ lat: 42.0635381, lng: -70.8603076 }}
                    />
                </GoogleMap>
            </div>
        </CardMedia>
    </Card>
);
    }
    

export default withScriptjs(withGoogleMap(FuneralHome));