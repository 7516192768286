// eslint-disable
// this is an auto generated file. This will be overwritten

export const createMemory = `mutation CreateMemory($input: CreateMemoryInput!) {
  createMemory(input: $input) {
    id
    title
    description
    person
    date
  }
}
`;
export const updateMemory = `mutation UpdateMemory($input: UpdateMemoryInput!) {
  updateMemory(input: $input) {
    id
    title
    description
    person
    date
  }
}
`;
export const deleteMemory = `mutation DeleteMemory($input: DeleteMemoryInput!) {
  deleteMemory(input: $input) {
    id
    title
    description
    person
    date
  }
}
`;
