
import React from "react";
import { Typography } from "@material-ui/core";

const Epitaph = (props) => (
    <div>
        <img src="/images/cross.png" width="75" alt="cross"/>
        <Typography variant="h2">
            Francis James Avery
        </Typography>
        <Typography variant="h4" style={{ 
            textAlign:"right",
        }}>
            1946-2019
        </Typography>
    </div>
)

export default Epitaph;
