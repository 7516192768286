import React from 'react';
import './App.css';
import FuneralHome from './components/FuneralHome';
import Epitaph from './components/Epitaph';
import { NewMemory, ViewMemory, AllMemories } from './components/Memory'
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import API, { graphqlOperation } from '@aws-amplify/api'
import PubSub from '@aws-amplify/pubsub';
import AWSAppSyncClient, { defaultDataIdFromObject } from "aws-appsync";
import { createMemory } from "./graphql/mutations";
import { ApolloProvider } from "react-apollo";
import awsconfig from './aws-exports';
API.configure(awsconfig);
PubSub.configure(awsconfig);




const useStyles = {
  root: {
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "center", 
    justifyItems:"center",
  },
  paper: {
    padding: 12,
    color: "black",
    margin: "auto", 
    fontFamily: "Lato",
    
  }
}

const client = new AWSAppSyncClient({
  url: awsconfig.aws_appsync_graphqlEndpoint,
  region: awsconfig.aws_appsync_region,
  auth: {
    type: awsconfig.aws_appsync_authenticationType,
    apiKey: awsconfig.aws_appsync_apiKey,
  },
  cacheOptions: {
    dataIdFromObject: (obj) => {
      let id = defaultDataIdFromObject(obj);
      return id;
    }
  }
});

class App extends React.Component {

  constructor() {
    super();
    this.state = {
      memories: [],
    }
    this.createMemoryFunc = this.createMemoryFunc.bind(this);
  }

  createMemoryFunc = (args) => {
    const { memories } = this.state 
    memories.push(args)
    this.setState({ memories })
    return  API.graphql(graphqlOperation(createMemory,{input: { description: args.description, person: args.person, title: args.title }}))
  }

  render() {
  const { memories } = this.state;
  return (
    <div className="Inner">
      <ApolloProvider client={client}>
        <Grid
          container
          style={{
            flexGrow: 1,
            flexDirection: "column",
            justifyContent: "center", 
            justifyItems:"center",
          }}
        >
          <Grid item style={useStyles.paper}>
            <Epitaph/>
          </Grid>


          <Grid style={{
            width: '75%',
            minWidth: 300,
            margin: 'auto',
          }}>
          <div className="DadPic">
          </div>
            <p>
              Family and friends are invited to come and celebrate the life of Francis James (Jim) Avery on August 2<sup>nd</sup> at the Sullivan Funeral Home at 2 Maquan Street in Hanson, MA between 2pm and 4pm and again between 7pm and 9pm. 
              Jim passed away on July 28 after a brief illness. He was 72 years old. A funeral mass will be held at St. Joseph the Worker at 1 Maquan Street in Hanson, MA on August 3<sup>rd</sup> at 11am.
            </p>
            <p>
              Born in Cambridge and raised in Boston, MA, Jim was educated in the Boston Public School System and joined the U.S. Army in 1966. During his deployment to Japan, he met and married his wife of 51 years, Elisabeth Mandeville Avery, and had his son, Keith Marshall Avery. His daughter, Meredith Lynn Avery, was born after the new family returned to Jim’s childhood Jamaica Plain neighborhood. After leaving the Army, Jim continued his job at Stone and Webster Corporation, where he would spend the next thirty-five years of his career and rise to the level of Vice President of Treasury during his tenure. He attended evening classes at Northeastern University, earning an Associate’s degree and a Bachelor of Science degree with honors in business management while working full time. Jim always demonstrated the values he espoused. He changed companies only once in his career, moving to Capgemini in 2000 until his retirement in 2013, illustrating two of his strongest traits: his deep sense of loyalty and his willingness to work hard.
            </p>
            <p>
              Throughout his life, Jim took every opportunity to travel and learn, heading to places as far away as Saudi Arabia and Africa, but always returning to his beloved city of Boston. His calm and capable demeanor made him an important mentor and guiding presence, both at work and in his extended family. He spent many years volunteering with the Boy Scouts, enjoying the time spent with his son and forming lifelong bonds with the other Troop 19 families. He was an active member of St. Joseph the Worker Church. A doting father and grandfather, Jim would invoke the most obscure holidays to slip his grandchildren money, much to their delight. 
            </p>
            <p>
              He is survived by his wife Elisabeth (Mandy) Avery, his son Keith Avery, his daughter Meredith Avery, and his grandchildren Ursula and James Avery, and Sebastien, Camille and Julien deCarbonnel. Jim was the loving brother of Janice Williams, Jeffrey Avery, Joel Avery and the late Joseph Avery and John (Jack) Avery. He was the son of the late Eleanor Avery and Francis J. Avery of Boston. He will also be missed by many aunts, cousins, nieces, nephews and friends. Donations in his honor may be made to 
              <a href="https://stjosephtheworkerhanson.weshareonline.org/"> St. Joseph the Worker Church in Hanson, Massachusetts</a>, or 
              <a href="https://donatenow.networkforgood.org/glastonburyabbey"> Glastonbury Abbey in Hingham, Massachusetts.</a>
            </p>
          </Grid>
          <Grid item style={useStyles.paper}>
            <FuneralHome 
              containerElement={<div style={{ height: `250px`, width:"300px"}} />}
              mapElement={<div style={{ height: `100%`, width:"100%" }} />}
              loadingElement={<div className="loading"/>}
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCDRp2eYxEXuALKvoYE4gf8OknhfNNuTBA"
            />
          </Grid>
          <Grid item style={useStyles.paper}>

            <NewMemory createMemory={this.createMemoryFunc}/>
          </Grid>

          <Grid item style={useStyles.paper}>
            <Typography variant="h4">
              Memories of Jim 
            </Typography>
            <AllMemories memories={memories}/>
          </Grid>



        </Grid>
      </ApolloProvider>
    </div>


  );
}

}

export default App;
