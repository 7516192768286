// eslint-disable
// this is an auto generated file. This will be overwritten

export const onCreateMemory = `subscription OnCreateMemory {
  onCreateMemory {
    id
    title
    description
    person
    date
  }
}
`;
export const onUpdateMemory = `subscription OnUpdateMemory {
  onUpdateMemory {
    id
    title
    description
    person
    date
  }
}
`;
export const onDeleteMemory = `subscription OnDeleteMemory {
  onDeleteMemory {
    id
    title
    description
    person
    date
  }
}
`;
