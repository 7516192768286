import React from "react";
import  { graphql } from "react-apollo";
import gql from "graphql-tag";
import { CircularProgress } from "@material-ui/core";

import { listMemorys } from "../../graphql/queries";
import { onCreateMemory } from "../../graphql/subscriptions"
import { ViewMemory } from "./ViewMemory";

export class AllMemories extends React.Component {
    constructor() {
        super();
        this.state = {busy: false}
    }
    handleSync = async () => {
        const { client } = this.props;
        const query = listMemorys;
        this.setState({ busy: true });
        client.subscribe({ query: onCreateMemory });
        await client.query({
            query,
            fetchPolicy: 'network-only',
        });
        this.setState({ busy: false });
    }
    render() {
        const { memories } = this.props;
        const { busy } = this.state;
        return busy 
            ? (<CircularProgress/>) 
            : (<div>{memories.filter(m => m.approved).map(m => (<ViewMemory key={m.id} memory={m}/>))}</div>) }
            
}

const AllMemoriesWithData = graphql(
    gql(listMemorys), 
    {
        options: {
            fetchPolicy: 'cache-and-network',
        },
        props: ({ data: { listMemorys = { items: [] } } }) => ({
            memories: listMemorys.items
        })
    },

)(AllMemories);

export default AllMemoriesWithData;