// eslint-disable
// this is an auto generated file. This will be overwritten

export const getMemory = `query GetMemory($id: ID!) {
  getMemory(id: $id) {
    id
    title
    description
    person
    date
    approved
  }
}
`;
export const listMemorys = `query ListMemorys(
  $filter: ModelMemoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listMemorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      description
      person
      date
      approved
    }
    nextToken
  }
}
`;
