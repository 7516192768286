import React from "react";
import  { graphql } from "react-apollo";
import gql from "graphql-tag";
import { getMemory } from "../../graphql/queries";
import { Card, CardContent, Typography, CircularProgress } from "@material-ui/core"
export class ViewMemory extends React.Component {
    render() {
        const { memory, loading } = this.props;
        if (loading) {
            return (<CircularProgress/>)
        }
        return (
            <Card className="Memory">
                <CardContent>
                    <Typography>
                        { memory.person }
                    </Typography>
                    <Typography>
                        { memory.title }
                    </Typography>
                    {memory.description.split('\n').map(function(item, key) {
                    return (
                        <span key={key}>
                        {item}
                        <br/>
                        </span>
                    )
                    })}
                </CardContent>
            </Card>
        )
    }
}

const ViewMemoryWithData = graphql(
    gql(getMemory), 
    {
        options: ({ match: { params: { id } } }) => ({
            variables: { id },
            fetchPolicy: 'cache-and-network',
        }),
        props: ({ data: { getMemory: memory, loading} }) => ({
            memory,
            loading,
        }),
    },

)(ViewMemory);


export default ViewMemoryWithData;