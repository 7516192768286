import React from "react";
import { TextField, Button, Container, Card, CardContent, CardActions, Typography, Snackbar } from "@material-ui/core";


class NewMemory extends React.Component {

    static defaultProps = {
        createMemory:  () => null,
    }

    state = {
        open: false,
        memory: {
            person: "",
            description: "",
            title: "",
        }
    }

    

    handleChange(field, { target: { value }}) {
        const { memory } = this.state;
        memory[field] = value;
        this.setState({ memory });
    }

    handleSave = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        const { createMemory } = this.props;
        const { memory } = this.state 
        createMemory({ ...memory })
        this.setState({open: true, memory: {title:"", description:"", person:memory.person}}) 
    }

    enabled = () => {
        const { memory } = this.state;
        return (memory.person !== "" && memory.name !== "" && memory.description !== "")
    }

    render() { 
        const { memory, open } = this.state;
        
        return (
            <div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={open}
                    autoHideDuration={2000}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    onClose={() => this.setState({open:false}) }
                    message={<span id="message-id">Thank you for adding your memory. It will appear in the list after review.</span>}
                />
                <Card style={
                    {
                        maxWidth: 400,
                        fontFamily: "Lato",
                        textAlign:"left",
                        marginTop:150,
                        backgroundColor: "#feffff",
                    }
                }>
                    <CardContent>
                        <Typography variant="h5">
                            Memory
                        </Typography>
                        <Typography>
                            Please share your favorite memory of Jim. We will review them and then publish them to the website. Thank you so much.
                        </Typography>
                        <form>
                        <Container style={{
                            margin: "20px 0px",
                        }}>
                                <TextField
                                    variant="outlined"
                                    value={memory.person}
                                    onChange={this.handleChange.bind(this, 'person')}
                                    placeholder="my name"
                                    label="my name"
                                />
                            </Container>
                            <Container style={{
                            margin: "20px 0px",
                        }}>
                                <TextField
                                    variant="outlined"
                                    value={memory.title}
                                    onChange={this.handleChange.bind(this, 'title')}
                                    placeholder="title"
                                    label="title"
                                    style={{ width:"100%" }}
                                />
                                <TextField
                                    variant="outlined"
                                    value={memory.description}
                                    onChange={this.handleChange.bind(this, 'description')}
                                    placeholder="my memory"
                                    multiline
                                    rowsMax={20}
                                    rows={8}
                                    label="my memory"
                                    style={{ width:"100%", marginTop:20 }}
                                />
                            </Container>
                        </form>
                    </CardContent>
                    <CardActions>
                        <Button disabled={!this.enabled()} onClick={this.handleSave}>Save</Button>
                    </CardActions>
                </Card>

            </div>

        )

    }
}

export default NewMemory;