// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://fm7y6f2kajhhlcvfrn3gl4tyf4.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-agujv4m2bjd4lb7kfiqypa2shq",
    "aws_content_delivery_bucket": "fjavery-20190723174642-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d3q6a1m8udy37a.cloudfront.net"
};


export default awsmobile;
